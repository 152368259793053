


















import Vue from 'vue';
import UserAgreementUtils, { UserAgreement } from '@/utils/UserAgreementUtils';
import { locales } from '@/i18n';

export default Vue.extend({
  computed: {
    table() {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('common.key'),
            value: 'key',
          },
          {
            text: this.$i18n.tc('common.name'),
            value: 'name_admin',
          },
          {
            text: this.$i18n.tc('user-agreement.active'),
            value: 'active',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },

    locales() {
      return locales;
    },
  },

  data: (): {
    items?: UserAgreement[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await UserAgreementUtils.userAgreement.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: UserAgreement) {
      await this.$router.push({
        name: 'user-agreement-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'user-agreement-create',
      });
    },
  },
});
